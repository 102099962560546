import { Link } from "@remix-run/react";
import config from "@/config.json";

export function Footer() {
  return (
    <div className="z-20 border-t border-common-border">
      <div className="container p-4 mx-auto divide-y divide-common-separator lg:max-w-screen-lg">
        <div className="flex flex-col justify-between gap-4 py-4 md:flex-row">
          <div className="flex flex-wrap items-center gap-4">
            <Link to={`https://x.com/${config.twitter}`}>Twitter</Link>

            <Link to="/by-a-human">By a human</Link>
          </div>

          <div>
            <p>Copyright © 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}
